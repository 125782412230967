<template>
    <div class="cart_main">
        <van-row  class="bottom_detail" >
            <van-row class="foot_line_height">
                <van-col span="14" >用戶名稱 <br>(User Name)</van-col>
                <van-col span="10" class="text-right warp"> {{userData.nickName}} </van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >手機號 <br>(Phone Number)</van-col>
                <van-col span="10" class="text-right warp"> {{userData.phonenumber}} </van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >密碼 <br>(Password)</van-col>
                <van-col span="10" class="text-right">
                    <van-button plain type="primary" size="mini" @click="modifyPassword">修改密碼</van-button>
                </van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >錢包 <br>(Wallet)</van-col>
                <van-col span="10" class="text-right warp"> ￡ {{walletNum}} <br>
                    <van-button plain type="primary" size="mini" @click="walletDetail" style="padding:15px 5px">錢包明細<br/>Detail</van-button>
                </van-col>
            </van-row>
        </van-row>
    </div>
</template>
<script> 
import {getWallet} from "../../api/personal"
    export default {
        data() {
            return {
                userData: {},
                walletNum:0
            }
        },
        created(){
            this.userData = JSON.parse(sessionStorage.getItem('userData'));
            this.getWallet()
        },
        methods:{
            getWallet() {
                getWallet().then(res => {
                    this.walletNum = res.data.amount;
                })
            },
            modifyPassword() {
                this.$router.push('/password')
            },
            walletDetail() {
                this.$router.push('/wallet/detail')
            }
        }
    }
</script>
<style scoped>
.cart_main {
    width: 93%;
    margin: 30px auto;
    background: #fff;
    border-radius: 20px;
}
.bottom_detail {
  padding: 20px 30px;
  font-size: 18px;
  height: 100%;
  width: 100%;
}
.foot_line_height {
    min-height: 60px;
    line-height: 60px;
    font-size: 15px;
}
.warp {
    word-break: break-all;
}
</style>